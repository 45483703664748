<template>
  <div>
    <v-btn
      color="primary"
      @click="isVisibleDialog = true"
    >
      {{ $t("send-command") }}
    </v-btn>
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="600px"
    >
      <v-form
        v-if="isVisibleDialog"
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("send-command") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="options.action"
                    dense
                    :items="commandList"
                    item-value="key"
                    item-text="value"
                    :label="$t('select_command')"
                  />
                </v-col>
              </v-row>

              <!-- set param fields -->
              <v-row v-if="options.action == 'setparam'">
                <v-col cols="6">
                  <v-text-field
                    v-model="options.param_no"
                    dense
                    type="number"
                    :label="$t('number_*')"
                    :rules="[rules.required, rules.max_length(3)]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="options.param_value"
                    dense
                    type="number"
                    :label="$t('value_*')"
                    :rules="[rules.required, rules.max_length(2)]"
                  />
                </v-col>
              </v-row>
              <!-- set param fields -->

              <!-- get param fields -->
              <v-row v-if="options.action == 'getparam'">
                <v-col cols="6">
                  <v-text-field
                    v-model="options.param_no"
                    dense
                    :label="$t('number_*')"
                    :rules="[rules.required, rules.max_length(3)]"
                  />
                </v-col>
              </v-row>
              <!-- get param fields -->
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :disabled="!options.action"
              :loading="isLoading"
              @click="sendCommand()"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  data() {
    return {
      isVisibleDialog: false,
      isLoadingSend: false,
      options: {},
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "flespiCommands/getIsLoading",
      commandList: "flespiCommands/getFlespiCommands",
    }),
  },
  watch: {
    "options.action": {
      handler: function (value) {
        if (value) {
          this.options = {
            action: value,
            imei: this.$route.params.imei,
          };
        }
      },
    },
  },

  // mounted() {
  //   this.getCommands();
  // },
  methods: {
    getCommands() {
      this.$store.dispatch("flespiCommands/flespiCommandsOptions");
    },
    openModal() {
      this.isVisibleDialog = true;
      this.getCommands();
    },
    closeForm() {
      this.options = {};
      this.isVisibleDialog = false;
    },
    sendCommand() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("flespiCommands/sendFlespiCommand", this.options)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("request_successful")
            );
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
          });
      }
    },
  },
};
</script>
